import '../css/academy.scss';
import {liveLoadData, submitData, dataFromForm, getData, submitDataFile} from './lib/livedata';
import {strToArray, scroll_to, formatBytes} from './lib/helpers';
import {getServerUrl, getSiteUrl, getUrlParam} from './lib/url';
import {minDays, shortDays, longDays, shortMonths, longMonths, dateMidFormat, dateMidScheme} from './lib/locales';
import {createCookie, checkCookie, readCookie} from './lib/cookie';
import {afterSend} from './anywhere';

// function TestVimeo() {
// 	let urlX = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/76979871';
// 	$.getJSON(urlX, function (data) { console.log(data); })
// 		.done(function() {
// 			console.log( "second success" );
// 		});
// }
$(document).ready(function () {
	var $box = $('.offer'),
		$searchForm = $('main').find('#searchForm'),
		$fill = $('main').find('#offerList'),
		hbox = $('.academy-offer-header');

	cookieSearchToSearchForm($searchForm);
	
	$('main').on('click', '.clear2up', function(){
		$(this).parent().parent().html('');
	});

	// TestVimeo();

	// input wyszukiwania
	$searchForm.on('keypress', 'input', function (e) {
		if (e.which === 13){
			e.preventDefault();
			return false;
		}
	});
	$searchForm.on('keydown', 'input', function (e) {
		if (e.which === 13){
			e.preventDefault();
			return false;
		}
	});
	$searchForm.on('keyup', 'input', function (e) {
		if (e.which === 13) {
			e.preventDefault();
			console.log('enter tu nie działa');
			return false;
		}else if ($(this).is("input[type=text]") || $(this).is("input[type=number]")) {
			console.log('szukam',$(this).val());
			searchToCookie($searchForm);
			liveLoadData($searchForm, $fill);
		}
	});

	// wyświetlanie/chowanie kierunków
	// ** NEW **
	$('main').on('click', 'button.switch', function(){
		var btn = $(this);
		console.log('click');
		btn.blur();

		if (btn.attr('aria-expanded') === 'false') {
			btn.find('i.fas').removeClass('fas fa-long-arrow-alt-down primary').addClass('fas fa-long-arrow-alt-up third');
			btn.removeClass('primary').addClass('third');
		} else {
			btn.find('i.fas').removeClass('fas fa-long-arrow-alt-up third').addClass('fas fa-long-arrow-alt-down primary');
			btn.removeClass('third').addClass('primary');
		}
	});

	$('main').on('click', '#scrollToOffer', function(e) {
		e.preventDefault();
		scroll_to('.main-bottom',800);
	});
	
	// dodanie specjalizacji
	$box.on('keypress', 'form .sf-add', function (e) {
		if (e.which === 13)	return false;
	});
	$('body').on('keydown', 'form .sf-add', function (e) {
		if (e.which === 13) return false;
	});
	$('body').on('keyup', 'form .sf-add', function (e) {
		if (e.which === 13) {
			console.log(e.which);
			studyFieldAdd($(this));
		}
	});
	$('body').on('click', 'form .add-sf', function () {
		studyFieldAdd($(this));
	});
	$('body').on('click', 'form .clear-sf', function () {
		studyFieldsClear($(this));
	});
	$('body').on('click', 'form .clear-tag-sf', function () {
		studyFieldRemove($(this));
	});
	$('body').on('click', 'form .clear-sf-exists', function () {
		studyFieldClear($(this));
	});

	// aktywacja edytora po otwarciu zakładki
	$('body').on('show.bs.collapse', '#collapseDescription', function(){
		var $textarea = $(this).find('#collapseDescription .editor-academy'),
			$editor = $(this).find('.note-editor');
		$textarea.summernote('enable');
		setTimeout(function(){
			var $toolbar = $editor.find('#collapseDescription .note-toolbar');
			$toolbar.css('width','100%;');
		}, 1000);
	});
	// deaktywacja edytora po zamknięciu zakładki
	$('body').on('hide.bs.collapse', '#collapseDescription', function(){
		var $textarea = $(this).find('#collapseDescription .editor');
		$textarea.summernote('disable');
	});


	// lightbox
	// główna fotka nagłówka, jeśli ma fotkę z galerii
	hbox.on('click', '.film .open-modal', function(){
		currentSlide($(this).data('slide'));
		openModal($('main').find('#modal-gallery'));
	});
    // videos, gallery itp - uniwersalne dla all-list
    $('main').on('click', '.all-list .open-modal', function(){
        var $btn = $(this),
            modalW = $btn.closest('.all-list').data('modal'),
            $modalW = $('main').find(modalW),
            nrSlide = $btn.data('slide');
        console.log('nrSlide', nrSlide);
        currentSlide(nrSlide, $modalW);
        openModal($modalW);
    });
    // modalne okno all-list
    $('main').on('click', '.modal-all-list .close', function(){
        closeModal($(this).closest('.modal-all-list'));
    });
    $('main').on('click', '.modal-all-list .prev', function(){
        var $modalW = $(this).closest('.modal-all-list');
        plusSlides(-1, $modalW);
    });
    $('main').on('click', '.modal-all-list .next', function(){
        var $modalW = $(this).closest('.modal-all-list');
        plusSlides(1, $modalW);
    });
	$('main').on('click', '.modal-all-list .slide-thumb', function () {
        var $modalW = $(this).closest('.modal-all-list');
		currentSlide($(this).data('slide'), $modalW);
	});

	// tu był admin menu

	// activation all tooltips
	$('[data-toggle*="tooltip"]').tooltip();


	$('.checkbox-block').change(
		function(){
			$('.checkbox-block-instance').prop('disabled', this.checked);
		});

	$('.checkbox-block-blocked').change(
		function(){
			$('.checkbox-block-instance-blocked').prop('disabled', !this.checked);
		});


	//
	//
	// console.log('start');
	// console.log($menu);
	// console.log($queryMenu);
	// console.log($menuAddButton);
	// console.log($menu);
	// console.log($submenu);
	//
	// let $stillHovered = false;
	//
	// $menuAddButton.hover(function () {
	// 	$stillHovered = true;
	// 	setTimeout(function(){
	//
	// 		if($stillHovered) {
	// 			console.log('hover dziala');
	// 			$($submenu).removeClass('d-none');
	// 			$($submenu).addClass('backInLeft');
	//
	// 			setTimeout(function(){
	// 				$($mainMenu).removeClass('moveRight');
	// 				$($mainMenu).addClass('moveLeft');
	//
	// 				$($submenu).removeClass('backInLeft');
	// 				$($submenu).removeClass('moveRight');
	// 				$($submenu).addClass('moveLeft');
	// 				//
	// 				// $($submenu).removeClass('backInLeft');
	// 				// $($submenu).removeClass('moveRight');
	// 				// $($submenu).addClass('moveLeft');
	//
	// 			}, 500);
	// 		}
	// 	}, 1000);
	// });





	//		$phraseBl = $form.find('#phraseName'),
//		$phraseChoosenName = $form.find('#phraseChoosenName'),
//		$chSch = $form.find('#choose-school'),
//		$chAcad = $form.find('#choose-academy'),
//		$choosenAcad = $form.find('#choosen-academy'),
//		$phrase = $phraseBl.find('input#search-phrase'),
//		$checked = "",
//		$phraseChoosen = $phraseChoosenName.find('input[name="phrase-choosen"');




	// $(form).find().on('shown.bs.modal', function(){
	// 	let selector = $(this).find(""),
	// 		itemCity = $(selector).find('item-city');
	//
	// 	alert(selector);
	// 	alert(itemCity);
	//
	//
	// });
	//
	// var elements = document.getElementsByClassName("item-selector");
	//
	// var myFunction = function() {
	// 	var attribute = this.getAttribute("data-myattribute");
	// 	alert(attribute);
	// };
	//
	// for (var i = 0; i < elements.length; i++) {
	// 	elements[i].addEventListener('click', myFunction, false);
	// }
	// $menuAddButton.mouseleave(function () {
	// 	$stillHovered = false;
	// });
	// $menu.mouseleave(function() {
	//
	// 	$($submenu).addClass('backOutLeft-expanded');
	// 	$($submenu).removeClass('moveRight');
	// 	$($submenu).removeClass('moveLeft');
	//
	// 	$($mainMenu).removeClass('moveLeft');
	// 	$($mainMenu).addClass('moveRight');
	//
	//
	// 	setTimeout(function(){
	// 		$($submenu).addClass('d-none');
	// 		$($submenu).removeClass('backOutLeft-expanded');
	//
	// 	}, 500);
	// });
	
	/////////// załadowanie events, news i gallery ////////////

	var url = $('body').data('siteUrl'),
		$fillEvents = $('#startEvents'),
		$fillGallery = $('#startGallery'),
		$fillNews = $('#startNews');

	// events
	getData(url + 'events/start/academy', $fillEvents);
	getData(url + 'academy/start/gallery', $fillGallery);
	getData(url + 'news/data/academy/0/start', $fillNews);

	const loader = document.querySelectorAll("[data-load]");
	loader.forEach(function(load) {
		load.dataset.load = load.dataset.load === "loading" ? "loaded" : "loading";
	});

	/////////// resize events, news i gallery ////////////
	$(window).on('resizeEnd', function(){
		getData(url + 'events/start/academy', $fillEvents);
		getData(url + 'academy/start/gallery', $fillGallery);
		getData(url + 'news/data/academy/0/start', $fillNews);
	});


});

function studyFieldRemove($obj){
	var $tag = $obj.closest('.tag'),
		$row = $obj.closest('.sf-row'),
		$tags = $row.find('.tags-area'),
		$add = $row.find('.sf-add'),
		$btnClear = $row.find('.clear-sf');
	$tag.remove();
	toggleDisplayClearSf($tags, $btnClear);
	$add.focus();
}

function studyFieldsClear($obj){
	var $inputArea = $obj.closest('.input-group'),
		$add = $inputArea.find('input'),
		$row = $inputArea.closest('.sf-row'),
		$tags = $row.find('.tags-area'),
		$btnClear = $row.find('.clear-sf');
	var sendOK = true;
	if($obj.data('confirm') !== undefined){
		sendOK = confirm($obj.data('confirm'));
	}
	if(sendOK){
		$tags.html('');
	}
	toggleDisplayClearSf($tags, $btnClear);
	$add.focus();
}

function studyFieldClear($obj){
	var $inputArea = $obj.closest('.input-group'),
		$add = $inputArea.find('input');
	console.log('clear', $add.prop('class'));
	$add.val('');
	$add.focus();
}

function studyFieldAdd($obj){
	var $inputArea = $obj.closest('.input-group'),
		$add = $inputArea.find('input'),
		$row = $inputArea.closest('.sf-row'),
		$tags = $row.find('.tags-area'),
		$messages = $row.find('.msg-area'),
		$btnClear = $row.find('.clear-sf');

	if($add.val().length > 2){
		$tags.append('<div class="input-group tag mb-1">' +
			'<input type="text" class="form-control-plaintext" name="newStudyFields[]" value="' + $add.val() + '" readonly>' +
			'<div class="input-group-append">' +
			'<button type="button" class="input-group-text btn btn-outline-secondary clear-tag-sf">' +
			'<i class="fas fa-times"></i>' +
			'</button>' +
			'</div>' +
			'</div>');

		$add.val('');
		$messages.html('');
		toggleDisplayClearSf($tags, $btnClear);
		$add.focus();
		return false;
	}else{
		$messages.html('<div><span class="msg">Wpisz minimum 3 znaki!</span><span class="clear-msg">&times;</span></div>');
		toggleDisplayClearSf($tags, $btnClear);
		$add.focus();
	}
}

function toggleDisplayClearSf($tags, $btnClear){
	console.log('toggle',$tags.html().length);
	if($tags.html().length){
		if($btnClear.hasClass('d-none')){
			$btnClear.removeClass('d-none');
		}
	}else{
		if(!$btnClear.hasClass('d-none')){
			$btnClear.addClass('d-none');
		}
	}	
}

/////// modal lightbox ///////
// Open the Modal
function openModal($mod) {
	console.log('modal',$mod);
	$mod.fadeIn(400);
}
// Close the Modal
function closeModal($mod) {
	$mod.fadeOut(400);
}

var slideIndex = 1;

// Next/previous controls
function plusSlides(n, $mod) {
	showSlides(slideIndex += n, $mod);
}

// Thumbnail image controls
function currentSlide(n, $mod) {
	showSlides(slideIndex = n, $mod);
}

function showSlides(n, $mod) {
	var i;
    var slides = $mod.find(".slide-image");
//    var names = $mod.find(".slide-name");
	var dots = $mod.find(".slide-thumb");
	if (n > slides.length) {
		slideIndex = 1;
	} else if (n < 1) {
		slideIndex = slides.length;
	} else {
		slideIndex = n;
	}
	for (i = 0; i < slides.length; i++) {
		slides[i].style.display = "none";
	}
//	for (i = 0; i < names.length; i++) {
//		names[i].style.display = "none";
//	}
	for (i = 0; i < dots.length; i++) {
		dots[i].className = dots[i].className.replace(" active", "");
	}
	slides[slideIndex - 1].style.display = "block";
//	names[slideIndex - 1].style.display = "block";
	dots[slideIndex - 1].className += " active";
}
/////// modal lightbox end ///////

function toggleArea($toHiddenObj, $toShowObj){
	$toHiddenObj.addClass('d-none');
	$toShowObj.removeClass('d-none');
}

/////// kopiowanie do schowka ///////
function copyToClipboard(sbox){
	var $group = sbox.find('#popup-url'),
		$input = $group.find("#clipboard-inp");
	$input.select();
//	$input.setSelectionRange(0, 99999); /*For mobile devices*/
	document.execCommand("copy");
	$group.hide(700);
	sbox.find('.fa-clipboard').addClass('ready');
}

function searchToCookie ($form){
	var data = dataFromForm($form);
	console.log('dataFromForm', data);	
	createCookie('search', data);
	console.log('nevCookie',readCookie('search'));
}

// kiedy się uruchamia strona
function cookieSearchToSearchForm($form){
	if(checkCookie('search')){
		console.log('check tak', checkCookie('search'));
		var data = readCookie('search');
	}else{
		console.log('check nie', checkCookie('search'));		
		var data = dataFromForm($form);
		createCookie('search', data);
	}
	console.log('data', data);
}

