export {minDays, shortDays, longDays, shortMonths, longMonths, dateMidFormat, dateMidScheme}
import {readCookie, createCookie, deleteCookie, checkCookie} from './cookie';

function minDays(){
	var locale = readCookie('_locale');
	console.log('locale minDays', locale);
//	createCookie('test','tester',1,'');
	if (locale === undefined){
		locale = 'en';
	}
	switch(locale){
		case 'pl':
			var response = ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'];
			break;
		default:
			var response = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
	}
	
	return response;
}

function shortDays(){
	var locale = readCookie('_locale');
	console.log('locale shortDays', locale);
	if (locale === undefined){
		locale = 'en';
	}
	switch(locale){
		case 'pl':
			var response = ['Nie', 'Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'];
			break;
		default:
			var response = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	}
	
	return response;
}

function longDays(){
	var locale = readCookie('_locale');
	console.log('locale longDays', locale);
	if (locale === undefined){
		locale = 'en';
	}
	switch(locale){
		case 'pl':
			var response = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
			break;
		default:
			var response = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	}
	
	return response;
}

function shortMonths(){
	var locale = readCookie('_locale');
	console.log('locale shortMonts', locale);
	if (locale === undefined){
		locale = 'en';
	}
	switch(locale){
		case 'pl':
			var response = ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'];
			break;
		default:
			var response = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	}
	
	return response;
}

function longMonths(){
	var locale = readCookie('_locale');
	console.log('_locale longMonts', locale);
	if (locale === undefined){
		locale = 'en';
	}
	switch(locale){
		case 'pl':
			var response = ['Styczeń', 'Luty', 'Marzec', 'Kwieceń', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
			break;
		default:
			var response = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	}
	
	return response;
}

function dateMidFormat(){
	var locale = readCookie('_locale');
	console.log('_locale longMonts', locale);
	if (locale === undefined){
		locale = 'ISO';
	}
	switch(locale){
		case 'pl':
			var response = 'dd.mm.yy';
			break;
		case 'en':
			var response = 'mm/dd/yy';
			break;
		default:
			var response = 'yy-mm-dd';
	}
	
	return response;
}

function dateMidScheme(){
	var locale = readCookie('_locale');
	console.log('_locale longMonts', locale);
	if (locale === undefined){
		locale = 'ISO';
	}
	switch(locale){
		case 'pl':
			var response = 'DD.MM.RRRR';
			break;
		case 'en':
			var response = 'MM/DD/YYYY';
			break;
		default:
			var response = 'YYYY-MM-DD';
	}
	
	return response;
}
